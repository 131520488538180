<template>
	<ViewLayout>
		<template #header-title>
			View Support Ticket
		</template>
	</ViewLayout>
</template>

<script>
import { mapGetters } from 'vuex'
import ViewLayout from '../../../../../shared/layouts/view-layout.vue'

export default {
	components: {
		ViewLayout
	},
	computed: {
		...mapGetters({
			user: 'auth/productAreaPermission'
		})
	}
}
</script>
